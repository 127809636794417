import React, { useEffect, useState } from 'react';

function StickyButton() {
    const [offset, setOffset] = useState(0);
    const handleBackClick = () => {
        window.scrollTo({
            top: -10,
            behavior: 'smooth'
        });
    }
    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        window.addEventListener("scroll", onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });

    })
    return (
        <>
            {offset > 200 && (
                <>
                    <div className='stickyButton'>
                        <button className=' ' onClick={handleBackClick}>
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20" cy="20" r="19.5" fill="#0F0F0F" stroke="url(#paint0_linear_14_5532)" />
                                <path d="M24.875 24.75L20.5 20.375L16.125 24.75" stroke="url(#paint1_linear_14_5532)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M24.875 24.75L20.5 20.375L16.125 24.75" stroke="url(#paint2_linear_14_5532)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M24.875 18.625L20.5 14.25L16.125 18.625" stroke="url(#paint3_linear_14_5532)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <defs>
                                    <linearGradient id="paint0_linear_14_5532" x1="20" y1="0" x2="20" y2="40" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#00936E" />
                                        <stop offset="1" stopColor="#00FFE0" />
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_14_5532" x1="20.5" y1="20.375" x2="20.5" y2="24.75" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#00936E" />
                                        <stop offset="1" stopColor="#00FFE0" />
                                    </linearGradient>
                                    <linearGradient id="paint2_linear_14_5532" x1="20.5" y1="20.375" x2="20.5" y2="24.75" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#00936E" />
                                        <stop offset="1" stopColor="#00FFE0" />
                                    </linearGradient>
                                    <linearGradient id="paint3_linear_14_5532" x1="20.5" y1="14.25" x2="20.5" y2="18.625" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#00936E" />
                                        <stop offset="1" stopColor="#00FFE0" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </button>
                    </div>
                </>
            )}
        </>
    );
};

export default StickyButton;