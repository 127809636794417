import React from 'react';
import ScrollDrag from './ScrollDrag';

import css from './style.module.css'


const ProjectView = (current) => {



    return (
        <>

            <div className={css.flexDiv}>
                <div className={css.currentProject}>
                    <div className={css.projectTitle}>
                        <h1>
                            {current['title']}
                        </h1>
                        <p className='primaryColor'>
                            {current['description']}
                        </p>
                        <div className={css.projectProgLang}>
                            {current['proglang'].map((e =>
                                <img key={e} src={e} alt='usedLand' />
                            ))}
                        </div>
                    </div>
                    <div>
                        <ScrollDrag slideClass={css.slideImdShow}>
                            {current['imgPath'].map((e) =>
                                <img key={e} src={e} alt='rectangle' />
                            )}
                        </ScrollDrag>
                    </div>
                </div>
            </div>
        </ >
    );
};

export default ProjectView;