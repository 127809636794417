import React from 'react';

import footligne from '../asset/image/footligne.png'

const Footer = () => {
    return (
        <>
            <div className='footer'>
                <img src={footligne} alt="footer" />
                <span className='primaryColor' >Copyright  ©2022 JM All rights reserved.</span>
            </div>
        </ >
    );
};

export default Footer;