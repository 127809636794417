import React from "react";
import PropTypes from "prop-types";

export class ScrollDrag extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            isScrolling: false,
            clientX: 0,
            scrollX: 0,
        };

    }



    onMouseDown = e => {
        if (!this.state.isScrolling) {
            this.setState({
                ...this.state,
                isScrolling: true,
                clientX: e.clientX,
            });
            e.preventDefault();
        }
    };
    onMouseMove = e => {
        if (this.state.isScrolling) {
            this.ref.current.scrollLeft -= (-this.state.clientX + (this.state.clientX = e.clientX));
        }
    };

    onMouseUp = () => {
        this.setState({
            ...this.state,
            isScrolling: false,
        });


    };
    render() {
        return (
            <div
                ref={this.ref}
                onMouseDown={this.onMouseDown}
                onMouseUp={this.onMouseUp}
                onMouseMove={this.onMouseMove}
                className={this.props.slideClass}
            >
                {React.Children.map(this.props.children, child =>
                    React.Children.only(child)
                )}
            </div>
        );
    }
}

ScrollDrag.defaultProps = {
    ref: { current: {} },
    rootClass: ""
};

ScrollDrag.propTypes = {
    ref: PropTypes.object,
    rootClass: PropTypes.string,
    children: PropTypes.array
};

export default ScrollDrag;
