import React, { useState } from 'react';

import right from '../../asset/image/right.png'
import letter from '../../asset/image/letter.png'
import css from './style.module.css'
import axios from "../../axios"

const Contact = () => {
    const [mail, setMail] = useState('');
    const [text, setText] = useState('');
    const handleMail = (event) => {
        setMail(event.target.value);
    };

    const handleText = (event) => {
        setText(event.target.value);
    };

    const handleClick = () => {
        axios
            .post("send/", {
                mail,
                text
            })
            .then((result) => {
                window.location.reload();
            })
            .catch((error) => {
                window.location.reload();
            });
    }
    return (
        <>
            <div className={css.contactForm}>
                <h1>Contactez-moi</h1>
                <img className={css.rigthLign} src={right} alt='left' />
                <div>
                    <div className={css.contactMe}>
                        <div>
                            <img src={letter} alt='letter' />
                        </div>
                        <div className={css.contactDiv}>
                            <div>
                                <input type='text' onChange={handleMail} placeholder='Votre e-mail' />
                            </div>
                            <div>
                                <textarea onChange={handleText} placeholder='Écrivez votre message' />
                            </div>
                            <div className={css.sendButtonDiv}>
                                <button
                                    className={`${!text || !mail ? css.inactiveButton : css.activeButton}`}
                                    onClick={handleClick}
                                    disabled={!text || !mail} >
                                    <b className={`${!text || !mail ? css.inactiveText : css.activeText}`} > send </b>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ >
    );
};

export default Contact;