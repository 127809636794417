import React from 'react';

import css from './style.module.css'
import left from '../../asset/image/left.png'
import php from '../../asset/image/php.png'
import js from '../../asset/image/js.png'
import java from '../../asset/image/java.png'
import figma from '../../asset/image/figma.png'
import html from '../../asset/image/html.png'
import sql from '../../asset/image/sql.png'
import mongo from '../../asset/image/mongo.png'
import node from '../../asset/image/node.png'
import react from '../../asset/image/react.png'
import tailwind from '../../asset/image/tailwind.png'
import laravel from '../../asset/image/laravel.png'
import symfony from '../../asset/image/symfony.png'
import cssLang from '../../asset/image/css.png'
import bootstrap from '../../asset/image/bootstrap.png'
import miu from '../../asset/image/miu.png'

const MyCollection = [
    {
        id: 1,
        imgPath: java,
        level: 3,
        sub: []
    },
    {
        id: 2,
        imgPath: php,
        level: 4,
        sub: [laravel, symfony]
    },

    {
        id: 4,
        imgPath: sql,
        level: 4,
        sub: [],
    },
    {
        id: 5,
        imgPath: mongo,
        level: 3,
        sub: []
    },
    {
        id: 3,
        imgPath: js,
        level: 3,
        sub: [react, node]
    },
    {
        id: 6,
        imgPath: html,
        level: 4,
        sub: []
    },
    {
        id: 7,
        imgPath: cssLang,
        level: 4,
        sub: [tailwind, bootstrap, miu]
    },
    {
        id: 8,
        imgPath: figma,
        level: 3,
        sub: []
    },


];
const Competence = () => {
    return (
        <>
            <div className={css.competence}>
                <h1>Compétences</h1>
                <img className={css.leftLign} src={left} alt="left" />
                <div className={css.competenceDiv}>
                    {MyCollection.map((e) =>
                        <div key={e.id} className={css.competenceSection} >
                            <div className={css.competenceItem}>
                                <img className={css.competenceItemImg} src={e['imgPath']} alt='js' />
                                <div className={css.line} >
                                    <hr />
                                </div>
                                <div className={css.circle} >
                                    {Array.from({ length: e['level'] }, (_, i) => <span className={css.circleColor1} key={i}></span>)}
                                    {Array.from({ length: (5 - e['level']) }, (_, i) => <span className={css.circleColor2} key={i}></span>)}
                                </div>
                            </div>
                            <div className={css.competenceSubItem}>
                                {e['sub'].map((el) => {
                                    return <img className={css.competenceSubItemImg} key={el} src={el} alt={el} />

                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </ >
    );
};

export default Competence;