import React from 'react';
import css from './style.module.css'
import right from '../../asset/image/right.png'
import ProjectView from './ProjectView';
import ScrollDrag from './ScrollDrag';

import dictionary from '../../asset/image/myprojects/dictionary.png'
import tolimob from '../../asset/image/myprojects/tolimob.png'
import tolidesk from '../../asset/image/myprojects/tolidesk.png'
import portmob from '../../asset/image/myprojects/portmob.png'
import portdesk from '../../asset/image/myprojects/portdesk.png'
import philhar from '../../asset/image/myprojects/philhar.png'
import portfolio from '../../asset/image/myprojects/portfolio.png'
import sql from '../../asset/image/sql.png'
import react from '../../asset/image/react.png'
import tailwind from '../../asset/image/tailwind.png'
import node from '../../asset/image/node.png'


const MyCollection = [
    {
        id: 1,
        title: 'Dictionnaire',
        imgPath: [dictionary, tolimob, tolidesk],
        description: 'Le premier dictionnaire en ligne du mongol vers le français',
        proglang: [node, react, tailwind, sql]
    },
    {
        id: 2,
        title: 'Site vitrine',
        imgPath: [philhar],
        description: 'Une site vitrine ',
        proglang: [react, tailwind]
    },
    {
        id: 3,
        title: 'Portfolio',
        imgPath: [portfolio, portmob, portdesk],
        description: 'Mon portfolio',
        proglang: [react]
    },


];

const Projets = () => {
    const ref = React.createRef();
    const [current, setCurrentProject] = React.useState(MyCollection[0]);
    return (
        <>
            <div className={css.projectView}>
                <div>
                    <h1>Projets</h1>
                    <img className={css.rigthLign} src={right} alt='right' />
                </div>
            </div>
            <div className={css.desktop}>
                {/* //   <Slider myCollections={MyCollection} /> */}
                <ProjectView {...current} />
                <div className={css.otherProject}>
                    <div ref={ref}>
                        <ScrollDrag slideClass={css.projectSlider}>
                            {MyCollection.map((e) =>
                                <img key={e.id} id={e.id} src={e.imgPath[0]} alt='rectangle' onClick={(e) => setCurrentProject(MyCollection[e.target.id - 1])} />
                            )}
                        </ScrollDrag>
                    </div>
                </div>
            </div>

        </ >
    );
};

export default Projets;