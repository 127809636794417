import React, { useRef } from 'react';


import Profile from './component/Profile/Profile';
import Projets from './component/Projets/Projets';
import Contact from './component/Contact/Contact';
import Footer from './component/Footer';
import StickyButton from './component/StickyButton';
import Competence from './component/Competence/Competence';


const Home = () => {
    const competence = useRef()
    const portfolio = useRef()
    const dot = useRef()
    const dotOutline = useRef()

    function scrollPortfolio() {
        portfolio.current.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" })
    }
    function scrollCompetence() {
        competence.current.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" })
    }



    return (
        <>
            <div ref={dotOutline} className='cursor-dot-outline'>
            </div>
            <div ref={dot} className='cursor-dot'>
            </div>
            <StickyButton />
            <Profile scrollPortfolio={scrollPortfolio} scrollCompetence={scrollCompetence} />
            <div ref={portfolio}>
                <Projets />
            </div>
            <div ref={competence}>
                <Competence />
            </div>
            <Contact />
            <Footer />
        </ >
    );
};

export default Home;