import React from 'react';

import profile from '../../asset/image/pro.jpg'
import logo from '../../asset/image/Jm.png'
import css from './style.module.css'
import discord from '../../asset/image/headerIcon/discord.png'
import link from '../../asset/image/headerIcon/link.png'
import mail from '../../asset/image/headerIcon/mail.png'
import github from '../../asset/image/headerIcon/github.png'
import behance from '../../asset/image/headerIcon/behance.png'
import dribbble from '../../asset/image/headerIcon/dribbble.png'


const Profile = (props) => {
    return (
        <>
            <div id="profile" >
                <img className={css.logo} src={logo} alt="logo" />
                <div className={css.profileDiv}>
                    <div className={css.profileImg}>
                        <img src={profile} alt="profile" />
                        <div className={css.Intro}>
                            <p className='primaryColor'>
                                Hello
                            </p>
                        </div>
                    </div>
                    <div>
                        <h1 className='primaryColor'>
                            JAMAA Saranbat
                        </h1>
                        <p className='primaryColor'>
                            Developpeuse WEB, <br /> basée à Strasbourg
                        </p>
                    </div>
                </div>
                <div className={css.buttonDiv}>
                    <button className='borderGradient' onClick={props.scrollPortfolio}><b className='primaryColor'>projets();</b></button>
                    <button className='borderGradient' onClick={props.scrollCompetence}> <b className='primaryColor'>compétences();</b></button>
                </div>
                <div className={css.contactIconDiv}>

                    <div className={css.contactIcon}>
                        <a href='https://github.com/JamaaSar' target="_blank" rel="noreferrer"  >
                            <img src={github} alt="icon" />
                        </a>
                        <a href='https://www.linkedin.com/in/jamaa-saranbat-49526616a' target="_blank" rel="noreferrer" >
                            <img src={link} alt="icon" />
                        </a>
                        <a href="mailto:saruul.jamaa@gmail.com?" target="_blank" rel="noreferrer">
                            <img src={mail} alt="icon" />
                        </a>
                        <a href='https://discord.com/channels/@me/357233972368900097' target="_blank" rel="noreferrer">
                            <img src={discord} alt="icon" />
                        </a>
                        <a href='https://dribbble.com/sjamaa' target="_blank" rel="noreferrer">
                            <img src={dribbble} alt="icon" />
                        </a>
                        <a href='https://www.behance.net/sjamaa' target="_blank" rel="noreferrer">
                            <img src={behance} alt="icon" />
                        </a>
                    </div>
                </div>
            </div>
        </ >
    );
};

export default Profile;