import axios from "axios";


axios.defaults.withCredentials = true


const instance = axios.create({
  baseURL: "https://sjamaa.fr/backend/",
});

export default instance;
